import handleCondition from "./handleConditionOnQuestion";
import * as yup from 'yup';

function getYupObjectPerQuestion(form, formData, question, t) {
    var schema = {};
    if ((question.fieldType !== "custom input" && question.fieldType !== "custom variable") && handleCondition(formData, question, form)) {
        switch (question.type) {
            case "string":
                schema[question.name] = yup.string();
                break;
            case "array":
                schema[question.name] = yup.array();
                break;
            case "number":
                schema[question.name] = yup.number();
                break;
            case "bool":
                schema[question.name] = yup.boolean();
                break;
            default:
                schema[question.name] = yup.mixed();
                break;
        }
        if (question.min) {
            schema[question.name] = schema[question.name].min(question.min)
        }
        if (question.max) {
            schema[question.name] = schema[question.name].max(question.max)
        }
        if (!question.readOnly && question.required) {
            schema[question.name] = schema[question.name].required(x => t("Required field", { questionName: question.label }))
        } else {
            schema[question.name] = schema[question.name].nullable()
        }
        if (question.subQuestions) {
            question.subQuestions.forEach(sb => {
                var generatedSchema = getYupObjectPerQuestion(form, formData, sb, t);
                Object.keys(generatedSchema).forEach(key => {
                    schema[key] = generatedSchema[key];
                })
            })
        }
    }
    return schema;
}

function getYupObject(form, formData, t) {
    var schema = {};
    form.forEach(question => {
        var generatedSchema = getYupObjectPerQuestion(form, formData, question, t);
        Object.keys(generatedSchema).forEach(key => {
            schema[key] = generatedSchema[key];
        })
    });
    return yup.object(schema);
}

export default getYupObject;