import React, { useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { logout } from "../../auth/verifyAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext, TypesContext } from "../../App";
import { FormTitleContext } from "./Global";
import { MsalInstanceContext } from "../../auth/AppProvider";
import { Button } from '../../components/catalyst-component/button'
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import "./tabs.css";

function Aside({ t }) {
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useContext(ThemeContext);
  const [formTitle,] = useContext(FormTitleContext);
  const [typesParameters, setTypesParameters] = useContext(TypesContext);

  useEffect(() => {
    var one_is_selected = typesParameters.find(t => t.selected === true);
    if (!one_is_selected) {
      setTypesParameters(tp => tp.map((t, k) => { t.selected = k === 0 ? true : false; return t }))
    }
  }, [typesParameters])

  const msalInstance = useContext(MsalInstanceContext)

  return <Disclosure as="nav">
    {({ open }) => (
      <><header className="">
        <div className="mx-5 px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-20 justify-between items-center">
            <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="cursor-pointer inline-flex items-center" onClick={() => {
                const baseUrl = window.location.origin + '/';
                window.location.replace(baseUrl);
              }}>
                <span className="sr-only">{t("Company Logo")}</span>
                {theme.header_logo ? <img
                  className="h-12 w-auto"
                  src={"data" + theme.header_logo?.split(";data")[1]}
                  alt={theme.header_logo?.split(';')[0]}
                /> : null}
              </div>
              {/* {formTitle && <div className="hidden sm:ml-12 sm:inline-flex items-center">
                <div className="h-7 flex items-center gap-2 rounded-full border border-dashed border-zinc-300 py-px pl-1 pr-3 text-xs/6 font-medium text-zinc-900">
                  <div className=" px-1 h-5 w-5 rounded-full bg-zinc-200">
                    <BookOpenIcon className="text-zinc-800 h-4" />
                  </div>
                  {formTitle}
                </div>
              </div>} */}
            </div>
            <ul className="hidden sm:flex sm:space-x-8 sm:justify-center sm:items-center">
              {typesParameters?.length > 1 && typesParameters.map(type =>
                <li className="tab cursor-pointer" onClick={() => {
                  var types = typesParameters;
                  navigate('/');
                  setTypesParameters(types.map(t => { t.selected = t.name === type.name ? true : false; return t }))
                }}>
                  <span className={type.selected ? "tab-selected" : ""}>{type.name}</span>
                </li>
              )
              }</ul>
            <div className="hidden sm:flex absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="relative ml-3">
                <div>
                  <Button className="bg-red-500 text-white" color="dark" onClick={() => {
                    if (location.pathname === "/") {
                      logout(msalInstance);
                    } else {
                      navigate("/");
                      logout(msalInstance);
                    }
                  }}>Déconnexion</Button>
                </div>
              </div>
            </div>
            <div className=" inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-900">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                  </svg>

                )}
              </DisclosureButton>
            </div>
          </div>
        </div>
      </header>
        <DisclosurePanel className="sm:hidden">
          <div className="space-y-1 pb-4 pt-2 w-full">
            {!formTitle ?
              typesParameters?.length > 1 && typesParameters.map(type =>
                <DisclosureButton as="div" className={type.selected ? "cursor-pointer block border-l-4 py-2 pl-3 pr-4 text-base font-medium" : "cursor-pointer block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:text-gray-700"} onClick={() => {
                  var types = typesParameters;
                  setTypesParameters(types.map(t => { t.selected = t.name === type.name ? true : false; return t }))
                }}>
                  <span>{type.name}</span>
                </DisclosureButton>
              )
              : <>
                <div className="h-7">
                  {formTitle}
                </div>
              </>}
            <div>
              <Button color="white" onClick={() => {
                if (location.pathname === "/") {
                  logout(msalInstance);
                } else {
                  navigate("/");
                  logout(msalInstance);
                }
              }}>Déconnexion</Button>
            </div>
          </div>
        </DisclosurePanel>
      </>
    )}
  </Disclosure>
}

export default withTranslation()(Aside);
